@font-face {
  font-family: 'Avenir-Regular';
  font-style: normal;
  font-weight: 400;
  src: local('Avenir-Regular'), url("../assets/fonts/AvenirNextLTPro-Regular.woff") format("woff");
}
@font-face {
  font-family: 'Avenir-Bold';
  font-style: normal;
  font-weight: 400;
  src: local('Avenir-Bold'), url("../assets/fonts/AvenirNextLTPro-Bold.woff") format("woff");
}
@font-face {
  font-family: 'Avenir-Demi';
  font-style: normal;
  font-weight: 400;
  src: local('Avenir-Demi'), url("../assets/fonts/AvenirNextLTPro-Demi.woff") format("woff");
}
@font-face {
  font-family: 'Avenir-It';
  font-style: normal;
  font-weight: 400;
  src: local('Avenir-It'), url("../assets/fonts/AvenirNextLTPro-It.woff") format("woff");
}
@font-face {
  font-family: 'Avenir-Medium';
  font-style: normal;
  font-weight: 400;
  src: local('Avenir-Medium'), url("../assets/fonts/AvenirNextLTPro-Medium.woff") format("woff");
}

@font-face {
  font-family: 'Avenir-Light';
  font-style: normal;
  font-weight: 400;
  src: local('Avenir-Light'), url("../assets/fonts/AvenirLTPro-Light.woff") format("woff");
}

@font-face {
  font-family: 'IM FELL English PRO Italic';
  font-style: normal;
  font-weight: normal;
  src: local('IM FELL English PRO Italic'), url('../assets/fonts/FeENit27C.woff') format('woff');
}
